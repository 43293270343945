.chip {
  display: inline-flex;
  align-items: center;
  padding: 3px 16px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid lightgray;
  user-select: none;
  &:hover {
    // background-color: $light-grey;
  }

  &.toggle{
    cursor: pointer;
    height: inherit;
    border-radius: 8px;
    color: #727272;
    fill: #727272;
    border: 1px solid #727272;
    background-color: $white;
    user-select: none;

    &:hover {
      background-color:#f5f5f5;
    }
  
    &.selected {
      border: 2px solid #171717;
      background-color:#f5f5f5;
      color: #171717;
      fill: #171717;
      span, p {
        font-weight: 500 !important;
      }
      &:hover {
        background-color:#f5f5f5;
      }
    }
  }
}

.chip__label {
  line-height: 28px;
  color: $dark-grey;
}

.chip--disabled .chip__delete {
  display: none;
}
