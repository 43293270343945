#modal-container {
  position: fixed;
  z-index: 9999;
  height: 100dvh;
  width: 100vw;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);

  .modal-middle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    max-width: 710px;

    // animation: appear 0.5s forwards;
  }

  .modal-content {
    position: relative;
    width: 100%;
    max-width: 711px;
    border-radius: 5px;
    overflow: hidden;
    height: initial;
    max-height: 90dvh;

    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // overflow: hidden;

    // max-height: 100vh;
    animation: appear 0.5s forwards;

    @include media-breakpoint-down(md) {
      height: 100dvh;
      max-height: 100dvh;
      // min-height: calc(100svh - 60px);
    }

    @keyframes appear {
      from {
        transform: translateY(100%);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .modal-body {
      max-height: calc(100dvh - 35px);
      overflow: scroll;
    }

    .close-modal {
      position: absolute;
      top: 8px;
      right: 0;
      cursor: pointer;

      font-size: 25px;
      padding: 0 13px;

      z-index: 9999;
    }

    .back-modal {
      position: absolute;
      top: 8px;
      left: 0;
      cursor: pointer;

      font-size: larger;
      padding: 0 13px;

      z-index: 9999;
    }
  }
}

// .modal-padding {
//   padding-left: 10px;
//   padding-right: 10px;
// }

.search-modal {
  transition: opacity 0.3s, transform 0.3s;
}

.search-modal.appear {
  opacity: 1;
  transform: translateY(0);
}

.search-modal.appear-from-bottom {
  opacity: 0;
  transform: translateY(100vh);
}

.for-cLient-modal {
  transition: opacity 0.3s, transform 0.3s;
  animation: appear-from-bottom 0.3s forwards;
}

@keyframes appear-from-bottom {
  from {
    opacity: 0;
    transform: translateY(100vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.modal-padding-card {
  padding-left: 2px;
  padding-right: 2px;
}

.modal-bottom-space {
  height: 0;
}

@media only screen and (min-width: 376px) {
  .modal-padding,
  .modal-padding-card {
    padding-left: 20px;
    padding-right: 20px;
  }
}
