.menuCatContainer {
  grid-template-columns: 1fr 1fr;
}

.menuCatTile {
  width: 160px;
  height: 160px;
  position: relative;
  background-size: cover;

  cursor: pointer;

  .velina {
    width: 100%;
    height: 100%;
    background-color: rgba(23, 23, 23, 0.2);
    position: absolute;
    top: 0;
    left: 0;
  }
  &:hover {
    .velina {
      background-color: transparent;
    }
  }
}

.menuCatAnim {
  opacity: 0;
  margin-top: -30px;
  animation: menuCatTileAppear 0.2s forwards;
}

@keyframes menuCatTileAppear {
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

.menuHamburger {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 16px;
  z-index: 101;
  .p1 {
    width: 22px;
    height: 1px;
    transform-origin: top right;
    transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out;
  }
  .p2 {
    width: 22px;
    height: 1px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, background-color 0.5s ease-in-out;
  }
  .p3 {
    width: 22px;
    height: 1px;
    transform-origin: bottom right;
    transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out;
  }
}

.menuHamburgerOpen {
  .p1 {
    background-color: black !important;
    transform: rotate(-45deg);
  }
  .p2 {
    opacity: 0;
  }
  .p3 {
    background-color: black !important;
    transform: rotate(45deg);
  }
}

.menuClose {
  width: 22px;
  height: 16px;
}

.menuLogo {
  transition: filter 0.5s ease-in-out;
}

.menuLogoOpen {
  filter: invert(1);
}

.menuCircle {
  position: absolute;
  width: 100vw;
  height: 100vw;
  margin-top: calc(-50% + 8px);
  margin-left: calc(-50% + 8px);
  border-radius: 100%;
  transform: scale(0);
  background-color: rgb(255, 255, 255);
  transition: transform 0.5s ease-in-out;
}

.menuHeight {
  width: 30px;
  height: 44px;
}

.menuCircleOpen {
  z-index: 1;
  transform: scale(5);
}

@media (min-width: 414px) {
  .menuTile {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 600px) {
  .menuClose {
    width: 18px;
  }
  .menuCatContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .menuCircleOpen {
    z-index: 1;
    transform: scale(2.5);
  }
}

@media only screen and (min-width: 1024px) {
  .menuHeight {
    height: 44px;
  }
  .menuTile {
    width: 250px;
    height: 250px;
  }
}
// Antonio resolution
@media only screen and (min-width: 769px) and (max-height: 600px) {
  .menuHeight {
    height: 21px;
  }
}
