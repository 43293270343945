.nft-card-2 {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  $padding: 20px;

  * {
    transition-duration: 0.5s;
  }

  .nft-card-content {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    display: flex;
    flex-direction: column;
    padding: $padding;
    position: relative;
    height: 100%;
    width: 100%;

    overflow: hidden;
    border-radius: 15px;

    .nft-card-chip {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 6px 12px;
      border-radius: 100px;
      background-color: $black-65;
      color: white;
      white-space: nowrap;
      max-width: fit-content;
      z-index: 2;
    }

    .nft-card-soldby {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 6px 12px;
      border-radius: 100px;
      background-color: $white;
      color: $black;
      white-space: nowrap;
      max-width: fit-content;
      z-index: 2;
    }

    .nft-card-info {
      display: flex;
      flex-direction: column;
      color: white;
      z-index: 2;

      span {
        margin: 0;
      }
    }

    .nft-card-actions {
      display: flex;
      gap: 8px;
      z-index: 2;
    }

    .nft-card-user-actions {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: $padding;
      z-index: 2;
    }

    .nft-black-bg {
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(0, 0, 0, 1) 95%
      );
    }
  }

  .nft-owner {
    display: flex;
    gap: 8px;

    .nft-owner-pfp {
      height: 45px;
      width: 45px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
    }

    .nft-owner-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $white;

      .check-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $blue;
        border-radius: 100%;
        padding: 1px;
      }
    }
  }
}
