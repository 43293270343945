.tab-container {
  position: relative;
  border-radius: 8px;
  background: $light-grey;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 4px;

  .tab-item {
    min-height: 24px;
    padding: 5px 35px !important;
  }
  .tab-selector {
    position: absolute;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 4px 3px 10px 1px rgba(0, 0, 0, 0.05);
  }
}
