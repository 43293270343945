.panel-button {
  position: relative;
  border-radius: 20px;
  width: 382px;
  height: 144px;
  padding: 20px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .panel-button-shadow {
      background: rgba(23, 23, 23, 0);
    }
  }

  & &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    z-index: 0;
  }

  & &-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(23, 23, 23, 0.2);
    z-index: 0;
  }

  & &-chip {
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid $light-grey;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    &.chip-primary {
      border: none;
      background-color: $primary;
    }
  }

  & &-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .panel-button {
    width: 643px;
  }
}
