@mixin generate-height-classes {
  @for $i from 1 through 1000 {
    .height-#{$i} {
      height: #{$i}px;
      min-height: #{$i}px;
    }
  }
}
@include generate-height-classes;

@mixin createMinHeightClasses {
  @for $i from 1 through 10 {
    .minh-#{100 * $i} {
      min-height: 100 * $i * 1px;
    }
  }
}
@include createMinHeightClasses;

@mixin generate-width-classes {
  @for $i from 1 through 1000 {
    .width-#{$i} {
      width: #{$i}px;
      min-width: #{$i}px;
    }
  }
}
@include generate-width-classes;

@mixin createMaxWidthClasses {
  @for $i from 1 through 10 {
    .mw-#{100 * $i} {
      max-width: 100 * $i * 1px;
    }
  }
}
@include createMaxWidthClasses;
