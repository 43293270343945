.tak-card {
  &:hover {
    .tak-card-shadow {
      background: linear-gradient(
        180deg,
        rgba(23, 23, 23, 0) 0%,
        rgba(23, 23, 23, 0.85) 100%
      );
    }
  }
  width: 100%;
  height: 550px;
  padding: 24px 24px 16px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: orange;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  & &-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(23, 23, 23, 0.1) 0%,
      rgba(23, 23, 23, 0.99) 100%
    );
    z-index: 0;
  }

  & &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  & &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    z-index: 1;
  }
  & &-bottom {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }
  & &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
  & &-footer {
    width: 100%;
    border-top: 1px solid $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
}

@media only screen and (min-width: 1024px) {
  .tak-card {
    width: 400px;
  }
}
