@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

$font-primary: "Poppins", sans-serif;
$font-play: "Playfair Display", serif;

.fs-h1-lg {
    font-size: 28px;
    line-height: 1.2;
}

.fs-h1 {
    font-size: 26px;
    line-height: 1.2;
}

.fs-h2 {
    font-size: 24px;
    line-height: 1.3;
}

.fs-h3 {
    font-size: 20px;
    line-height: 1.3;
}

.fs-h4 {
    font-size: 20px;
    line-height: 1.4;
}

.fs-body-lg {
    font-size: 18px;
    line-height: 1.6;
}

.fs-body {
    font-size: 16px;
    line-height: 1.6;
}

.fs-body-sm {
    font-size: 14px;
    line-height: 1.6;
}

.fs-body-xs {
    font-size: 12px;
    line-height: 1.6;
}

@media only screen and (min-width: 769px) {
    .fs-h1-lg {
        font-size: 50px;
        line-height: 1.2;
    }
    
    .fs-h1 {
        font-size: 42px;
        line-height: 1.2;
    }
    .fs-h2 {
        font-size: 32px;
        line-height: 1.3;
    }
    
    .fs-h3 {
        font-size: 24px;
        line-height: 1.4;
    }
}