.snap-container-column {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;

  .snap-element-column {
    scroll-snap-align: start;
  }
}

.snap-container-row {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  /* Ensures the snap effect is mandatory for each snap item */
  width: 100%; /* or any specific size */

  .snap-element-row {
    scroll-snap-align: center;
    flex: 0 0 auto; /* Do not grow, do not shrink, basis auto */
    width: 300px; /* Example width, adjust as needed */
    margin: 0 auto; /* Center the items if they're smaller than the container */
  }
}
