.hotel-result {
  border: 1px solid lightgray;
  position: relative;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.05);

  .suggested-badge {
    position: absolute;
    left: 0;
    top: 36px;
    padding: 5px 15px;
    z-index: 10;
  }

  .image-container {
    position: relative;
    padding: 12px;
    overflow: hidden;
    border-radius: 20px 20px 0 0;

    display: flex;

    .hotel-image {
      max-height: 190px;
      min-height: 190px;
      max-width: unset;
      min-width: unset;

      width: 100%;

      transform: scale(1.17);

      object-fit: cover;

      @media only screen and (min-width: 768px) {
        max-height: 190px;
        min-height: 190px;
        max-width: 190px;
        min-width: 190px;

        border-radius: 20px;
        transform: scale(1);
      }
    }

    .image-nav {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      top: 95px;
      left: 0;
      right: 0;
      padding: 0 30px;
      z-index: 10;

      .nav-icon {
        height: 28px;
        width: 28px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 100%;
      }
    }
    @media only screen and (min-width: 768px) {
      overflow: visible;
    }
  }

  .hotel-details {
    padding: 20px;

    .top-choice-badge {
      padding: 0px 8px;
      border-radius: 20px;
    }

    .review-score {
      transform: translateY(1px);
    }

    .horizontal-divider {
      margin: 12px 0;
    }
  }

  .price-details {
    width: 100%;
    min-width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    padding-top: 0;
    .cut-price {
      text-decoration: line-through;
      color: red;
    }

    .advantages-section {
      background: rgba(140, 231, 134, 0.2);
      padding: 8px 10px;
    }

    @media only screen and (min-width: 768px) {
      padding-top: 20px;
      width: 265px;
      min-width: 265px;
      border-left: 1px solid lightgray;
    }
  }
}

.custom-pin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .pin-box {
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    color: black;
    white-space: nowrap;

    border-radius: 8px;
    border: 1.5px solid #9d9d9d;
    background: #fff;
    box-shadow: 4.827px 4.827px 16.09px 0px rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: black;
      color: white;
      z-index: 200;
    }

    &.selected {
      background-color: black;
      color: white;
      z-index: 200;
    }
  }

  // .pin-arrow {
  //   width: 0;
  //   height: 0;
  //   border-left: 5px solid transparent;
  //   border-right: 5px solid transparent;
  //   border-top: 5px solid #ccc;
  //   margin-top: -1px;
  // }
}
