#app-auth {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background-color: white;

  z-index: 8888;

  .slide-bg-container-mobile {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;

      background-color: black;
      z-index: -1;

      transition-duration: 0.5s;
    }

    &.login {
      &::after {
        left: 100%;
      }
    }
    &.register {
      &::after {
        left: -100%;
      }
    }
  }

  .ask-overlay {
    position: absolute;
    width: 50%;
    transition-duration: 0.3s;

    top: 0;

    &.register {
      left: 0;
    }

    &.login {
      left: 50%;
    }
  }
}
