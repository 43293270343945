#swipes-app {
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  #swipes-app-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .bottom-menu {
      border-top: 0.5px solid var(--Dark-grey, #9d9d9d);
      box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.2);
    }

    .loading-screen {
      position: fixed;
      z-index: 99999999;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;

      img {
        width: 90%;
        max-width: 600px;
      }

      @keyframes loading-disappear {
        0% {
          opacity: 1;
        }
        99% {
          opacity: 0;
        }
        100% {
          opacity: 0;
          visibility: hidden;
          display: none;
        }
      }

      animation: loading-disappear 0.5s forwards 4s;
    }

    .nav-link-divider {
      height: 100%;
      width: 1px;
      background-color: black;
      margin: 0 25px;
    }
  }
}
