#hub {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  overflow: hidden;
}

.hub-loader-container {
  pointer-events: none;
  position: fixed;

  animation: hub-loader 0.75s forwards ease-in-out 4s;

  background-color: $black;

  top: 0;
  left: -20px;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999999999;

  @keyframes hub-loader {
    to {
      margin-bottom: 100vh;
      margin-right: 98vw;
      scale: 0;
      opacity: 0;
      background-color: transparent;
    }
  }

  .hub-loader {
    display: flex;
    align-items: flex-end;
    position: relative;

    .loader-takyon {
      width: 85vw;
      position: relative;

      transform: translateX(33vw);

      animation: loader-takyon-1 0.75s forwards 1s,
        loader-takyon-2 0.75s forwards 2s;

      @keyframes loader-takyon-1 {
        0% {
          transform: translateX(33vw);
        }
        100% {
          transform: translateX(20vw);
        }
      }

      @keyframes loader-takyon-2 {
        0% {
          transform: translateX(20vw);
        }
        100% {
          transform: translateX(0);
        }
      }

      .loader-takyon-img {
        width: 100%;
      }

      &::after {
        content: '';
        position: absolute;
        background-color: $black;

        top: -10px;
        bottom: -10px;
        right: 0;
        width: 100%;

        animation: loader-takyon-after-1 0.75s forwards 1s,
          loader-takyon-after-2 0.75s forwards 2s;

        @keyframes loader-takyon-after-1 {
          0% {
            width: 100%;
          }
          100% {
            width: 50%;
          }
        }
        @keyframes loader-takyon-after-2 {
          0% {
            width: 50%;
          }
          100% {
            width: 0%;
          }
        }
      }

      .loader-t {
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 200;

        .loader-t-img {
          height: 100%;
          width: unset;
        }
      }
    }

    .green-dot {
      position: absolute;

      height: 3vw;
      width: 3vw;

      bottom: 5.2vw;
      right: 75%;

      background-color: $primary;

      z-index: 300;

      animation: green-dot 0.75s forwards, green-dot-1 0.75s forwards 1s,
        // green-dot-2 0.24s forwards 1.75s,
          green-dot-3 0.75s forwards 2s;

      @keyframes green-dot {
        0% {
          opacity: 0;
          transform: translateY(-500px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      @keyframes green-dot-1 {
        0% {
          right: 75%;
        }
        100% {
          right: 43%;
        }
      }
      // @keyframes green-dot-2 {
      //   0% {
      //     transform: translateY(0);
      //   }
      //   50% {
      //     transform: translateY(-100px);
      //   }
      //   100% {
      //     transform: translateY(0);
      //   }
      // }
      @keyframes green-dot-3 {
        0% {
          right: 43%;
        }
        100% {
          right: -6%;
        }
      }
    }
  }
}
