.btn {
  color: $black;
  fill: $black;
  background-color: $primary;

  border: 1px solid lightgray;

  white-space: nowrap;
  border-radius: 8px;
  box-shadow: 4px 3px 10px 1px rgba(0, 0, 0, 0.05);
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  transition-duration: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding: 8px 26px;
  font-size: 23px * $scale;

  position: relative;

  &.btn-nav {
    font-size: 12px;
  }

  .btn-notification {
    position: absolute;
    top: -5px;
    right: -5px;

    border-radius: 100%;
    height: 17px;
    width: 17px;

    background-color: $red;
    color: $white;
    fill: $white;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;
  }

  &.btn-sm {
    padding: 6px 20px;
    font-size: 16px * $scale;
    @media only screen and (min-width: 769px) and (max-height: 600px) {
      padding: 3px 10px;
    }
  }

  &.btn-sm-xs {
    padding: 6px 20px;
    font-size: 14px * $scale;
  }

  &.btn-lg {
    padding: 10px 25px;
    font-size: 24px * $scale;
  }

  &.btn-responsive {
    @include media-breakpoint-down(md) {
      padding: 6px 20px;
      font-size: 16px * $scale;
    }
  }

  &.btn-blank {
    background-color: transparent;
    border: transparent;
    box-shadow: unset;
    font-weight: 300;

    padding: 0;

    &:hover {
      background-color: transparent !important;
    }

    &.btn-selected {
      font-weight: 500;
    }
  }

  &:hover {
    background-color: $hover-primary !important;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  // secondary
  &.btn-secondary {
    background-color: $mid-grey;

    border: 1px solid $grey-line;

    &:hover {
      background-color: $hover-mid-grey !important;
    }

    &.btn-selected {
      background-color: $primary !important;
    }
  }

  // dark
  &.btn-dark {
    background-color: $black;

    &:hover {
      background-color: $black !important;
    }

    color: $white !important;
    fill: $white !important;
  }

  // light
  &.btn-light {
    background-color: $white;

    &:hover {
      background-color: $light-grey !important;
    }
  }

  &.btn-light-simple {
    background-color: $white;
    color: $black !important;
    fill: $black !important;
    gap: 4px !important;

    &:hover {
      background-color: $white !important;
    }
  }

  // blank
  &.btn-blank-light {
    background-color: unset;
    color: $white !important;
    fill: $white !important;
    border: 1px solid $white;

    &.btn-selected {
      background-color: $mid-grey !important;
      color: $black !important;
      fill: $black !important;

      &:hover {
        background-color: $light-grey !important;
      }
    }

    &:hover {
      background-color: $hover-mid-grey !important;
      color: $black !important;
      fill: $black !important;
    }
  }

  &.btn-blank-all {
    background-color: unset;
    color: $dark-grey !important;
    fill: $dark-grey !important;

    &.btn-selected {
      border-color: $white;
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white !important;
      fill: $white !important;

      &:hover {
        background-color: $light-grey !important;
      }
    }

    &:hover {
      background-color: $hover-mid-grey !important;
      color: $black !important;
    }
  }

  &.btn-blank-primary {
    background-color: unset;
    color: $white;

    &.btn-selected {
      background-color: $primary !important;
      color: $black;
      fill: $black;

      &:hover {
        background-color: $hover-mid-grey !important;
      }
    }

    &:hover {
      background-color: $hover-mid-grey !important;
      color: $black;
      fill: $black;
    }
  }

  &.btn-likes {
    padding: 6px 14px;
    border-radius: 38px;
    font-weight: 400;
    color: $dark-grey;
    fill: $dark-grey;
    box-shadow: unset;
  }

  &.btn-chip {
    padding: 6px 14px;
    border-radius: 38px;
    font-weight: 400;
    color: $dark-grey;
    fill: $dark-grey;
    box-shadow: unset;

    &:hover {
      background-color: $hover-mid-grey !important;
      color: $black;
      fill: $black;
    }

    &.btn-selected {
      background-color: $primary !important;
      color: $black;
      fill: $black;

      &:hover {
        background-color: $primary !important;
      }
    }
    @media only screen and (min-width: 769px) and (max-height: 600px) {
      padding: 3px 10px;
    }
  }

  &.btn-text {
    text-decoration: underline;
    font-weight: 500;
  }

  // &.btn-menu {
  //   background-color: unset;
  //   color: $dark-grey !important;
  //   fill: $dark-grey !important;
  //   border-radius: 100px;

  //   &.btn-selected {
  //     background-color: $primary !important;
  //     color: $black !important;
  //     fill: $black !important;
  //   }

  //   &:hover {
  //     background-color: $light-black !important;
  //     color: $white !important;
  //     fill: $white !important;
  //   }
  // }

  // icon only
  &.btn-icon {
    padding: 10px;
    aspect-ratio: 1/1;

    &.btn-sm {
      padding: 6px;
    }
  }

  &.btn-outline {
    border: 0.5px solid $dark-grey;
    box-shadow: unset;
  }

  &.btn-circle {
    border-radius: 100% !important;
  }

  .btn-icon-load {
    animation: btn-icon-load-rotate infinite 1s;
    @keyframes btn-icon-load-rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  &.mobile-full {
    width: 100% !important;
  }
}

@media only screen and (min-width: 768px) {
  .btn {
    &.mobile-full {
      width: initial !important;
    }
    &.btn-nav{
      font-size: 23px * $scale;
    }
  }
}
