$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 0px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

#swipes-app-main {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .main-container {
    display: flex;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .main-content {
      display: flex;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }
}

.tak-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: #f5f5f5;
  &:horizontal {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  display: none;
  &:horizontal {
    display: none;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: $dark-grey;
  &:horizontal {
    display: none;
  }
}

#hubspot-messages-iframe-container {
  z-index: 9998 !important;
}

@media screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    display: initial;
  }
}

@media (min-width: 1400px) {
  .tak-container {
    max-width: 1700px * $scale;
  }
}

.containerScroll {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;

  @media (min-width: map-get($grid-breakpoints, md)) {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    max-width: map-get($container-max-widths, md);
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: map-get($container-max-widths, lg);
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    max-width: map-get($container-max-widths, xl);
  }

  @media (min-width: map-get($grid-breakpoints, xxl)) {
    max-width: map-get($container-max-widths, xxl);
  }

  .multi-select-container{
    padding-bottom: 10px;
  }
}

@keyframes talk-animation {
  0%, 100% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(0.95);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.05);
  }
}

.talk {
  animation: talk-animation 0.8s ease-in-out infinite;
}

.baloonTalk{
  overflow: hidden;
  font-size: 12px;
  position: absolute;
  top: 0;
  background-color: #8b8b8b;
  height: 36px;
  min-height: 36px;
  color: #8b8b8b00;
  transition: background-color 0.8s ease, width 0.8s ease, height 0.8s ease;
}
.baloonTalkOpened{
  height: initial;
  background-color: #e7e7e7;
  color: #000;
}

.balloon {
  opacity: 1;
  
  &.typing {
    opacity: 1;
  }
}