.accordion-item {
  border: none;
  width: 100%;
}

.accordion-header {
  border: none;
  width: 100%;

  .accordion-button {
    background-color: white;
    box-shadow: none;
    width: 100%;
    padding-left: 0;
    font-size: 18px;
    line-height: 1.6;
    font-weight: 500;

    .collapsed {
      width: 100%;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(0deg);
      transition: transform 0.2s ease-in-out;
    }
  }

  .accordion-button:not(.collapsed) {
    border: none;
    background-color: none;
    color: $black;
    box-shadow: none;
    width: 100%;

    &.hover {
      border: none;
    }

    &::after {
      // content: "close";
      // font-family: "Material Icons";
      // background-image: none;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(180deg);
    }
  }

  &.wallet {
    .accordion-button {
      background-color: #f5f5f5;
    }
  }
}

.accordion-body {
  border: none;
  background-color: white;
  white-space: pre-line;

  &.wallet {
    background-color: #f5f5f5;
  }
}
