#wallet {
  background-color: $white;
  transition-duration: 0.3s;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;

  overflow: hidden;

  transition-duration: 0.1s;

  &.shake {
    animation: shake 0.1s infinite;
    @keyframes shake {
      from {
        transform: translateX(-10px);
      }
      to {
        transform: translateX(10px);
      }
    }
  }

  margin: 0;

  z-index: 6666;

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.close {
      transform: translateX(100vw);
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 500px;
    min-width: 500px;
    position: relative;

    &.close {
      max-width: 0;
      min-width: 0;
    }
  }

  &.fullpage {
    max-width: unset !important;
    min-width: unset !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  // @media (min-width: 1400px) {
  //   max-width: 750px;
  //   min-width: 750px;
  //   position: relative;

  //   &.close {
  //     max-width: 0;
  //     min-width: 0;
  //   }

  //   .nft-card-responsive-container {
  //     width: 33%;
  //   }
  // }

  .wallet-container {
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-color: $light-grey;
    position: relative;

    .wallet-content {
      overflow-y: scroll;
      overflow-x: hidden;

      width: 100%;
      height: 100%;

      padding: 15px;

      @include media-breakpoint-down(md) {
        padding: 5px;
      }
    }
  }

  .wallet-page {
    position: absolute;
    top: 50px;
    right: 0;
    left: 0;
    bottom: 0;

    z-index: -1;

    transition-duration: 0.5s;
    background-color: white;

    display: flex;
    flex-direction: column;

    &.open {
      z-index: 7777;
    }

    .wallet-page-content {
      overflow-y: scroll;
      background-color: $light-grey;
    }

    .wallet-page-button {
      position: absolute;
      right: 25px;
      top: 33px;
    }
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }
}
